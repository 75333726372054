header{
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}
.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}
/*CTA*/
.cta{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

}
/*socials*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position:absolute;
    left: 0;
    bottom: 3rem;

}
.header__socials::after{
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}
/*me*/
.me{
    background:linear-gradient(var(--color-primary),transparent);
    width:22rem;
    height: 25rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 3rem;
    border-radius:12rem 12rem 0 0 ;
    overflow: hidden ;
    padding: 0.5rem 2.5rem -1rem 1rem;

}
/*scril*/
.scroll__down{
    position: absolute;
    right: 2rem;
    bottom: 3rem;
    transform: rotate(90deg);
    font-size:0.9rem;
    font-weight: 300;
}
/*media queries medium devices- */
@media screen and(max-width:1024px){
   header{
    height: 68vh;
   }
}


/*media queries small devices devices- */
@media screen and 
  (max-width: 600px){
  header{
    height: 100vh;
  }
  .header__socials,.scroll__down{
    display: none;
  }
}
